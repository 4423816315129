import React from 'react'
import LayoutStatic from '../../../components/LayoutStatic';
import { Link } from 'gatsby';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
  },
  button: {
    marginTop: '10px',
    marginRight: '10px',
    width: '100%'
  }
}

const FinemotorNwritingPage = () => {
  return (
    <LayoutStatic>
      <div className="card" style={style.card} >
        <img src="/img/FinemotorNwriting.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">แบบประเมินพัฒนาการด้านกล้ามเนื้อมัดเล็กและการเขียน</h5>
          {/* <p className="card-text">สุขภาพจิตของคนไทยขณะนี้น่าเป็นห่วง เพราะปัญหารุมเร้า เศรษฐกิจ โรคระบาด อย่าปล่อยให้มีผผลกับคุณโดยไม่รู้ตัว ประเมิน ฟรี!!</p> */}
          {/* <p className="card-text" style={{ color: 'red' }}>(กำลังอยู่ในขั้นตอนการพัฒนา)</p> */}
          <Link to="/questionnaire?questionnaireId=3" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 1 ปี</Link>
          <Link to="/questionnaire?questionnaireId=4" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 2 ปี</Link>
          <Link to="/questionnaire?questionnaireId=5" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 3 ปี</Link>
          <Link to="/questionnaire?questionnaireId=6" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 4 ปี</Link>
          <Link to="/questionnaire?questionnaireId=7" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 5 ปี</Link>
          <Link to="/questionnaire?questionnaireId=8" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 6 ปี</Link>
          <Link to="/questionnaire?questionnaireId=9" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 7 ปี</Link>
          <Link to="/questionnaire?questionnaireId=10" className="btn btn-primary" style={style.button}>สำหรับเด็กอายุ 8 ปี</Link>
          {/* <Link to="/questionnaire?questionnaireId=44" className="btn btn-primary" style={style.button}>แบบคัดดรองภาวะกลืนลำบากในผู้สูงอายุ</Link>
          <Link to="/questionnaire?questionnaireId=45" className="btn btn-primary" style={style.button}>แบบคัดกรองสุขภาวะทางตา</Link>
          <Link to="/questionnaire?questionnaireId=46" className="btn btn-primary" style={style.button}>แบบคัดกรองภาวะการรู้คิดในผู้สูงอายุ</Link>
          <Link to="/questionnaire?questionnaireId=48" className="btn btn-primary" style={style.button}>คัดกรองจำแนกระดับโรคความดันโลหิตสูง</Link> */}
          
          {/* <Link to="#" className="btn btn-primary" style={style.button}>ตรวจความเสี่ยง อัลไซม์เมอร์</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>กระดูก และ ข้อ</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>เบาหวาน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค พาคินสัน</Link>
          <Link to="#" className="btn btn-primary" style={style.button}>โรค ไทรอยด์</Link> */}
          <p></p>
          {/* <p className="card-text" style={{ color: 'blue' }}>(แบบคัดกรองอื่นๆ..กำลังพัฒนา)</p> */}
        </div>
      </div>
    </LayoutStatic>
  );
}

export default FinemotorNwritingPage;

